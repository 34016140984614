.cancel_button, .get_started {
    background: black !important;
    color: white !important;
    opacity: 0.8;
    border: 0.1px solid lightgray;
    display: flex;
    align-items: center;
    padding-left: 3%;
    padding-right: 2%;
    border-radius: 16px !important;
    font-weight: bold;
    border-radius: 18px;
    min-width: 100% !important;
  }


